import Vue from 'vue'

import router from "@/router";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

// axios
import axios from 'axios';
// baseurl
const baseURL = process.env.VUE_APP_API_URL || "https://api-dev.edubot.com.br/api";


const axiosIns = axios.create({
  baseURL: baseURL,
  headers: {
    post: {
        'Content-Type': 'application/json'
    },
    common: {
      Accept: 'application/json'
    }
}
})

axiosIns.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('accessToken')
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let errorCode = 500;
    if (!error.response) {
      router.push("/api-off-line").catch(() => {});
    } else {
      errorCode = error.response.status;
    }
    const errorMessage = error.response.data;
    switch (errorCode) {
      case 401: {
        const errorMessage = error.response.data;
			  localStorage.removeItem("accessToken");
			  localStorage.removeItem("expiresIn");
        if (errorMessage.error == 'invalid_credentials') {
          return errorMessage.error;
        }
        if (errorMessage == 'Token is Invalid' || errorMessage == 'Authorization Token not found') {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'UserXIcon',
              text: "Falha na autenticação.",
              variant: 'danger',
              timeout: 5000
            },
          })
          
          router.push({ name: 'login'}).catch((error) => {});
        } else if (errorMessage == 'Token is Expired') {
          
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'UserXIcon',
              text: "Por favor, se autentique novamente.",
              variant: 'danger',
              timeout: 5000
            },
          })
          router.push("/login").catch(() => {});
        } else if (errorMessage.error == 'Unauthorized' || errorMessage == 'User not found') {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro.',
              icon: 'LockIcon',
              text: "Por favor verifique suas credenciais.",
              variant: 'danger',
              timeout: 5000
            },
          })
          router.push("/login").catch(() => {});
        } else{
          router.push("/login").catch(() => {});
        }
        break;
      }
      case 402 : { 
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro.',
            icon: 'LockIcon',
            text: "Usuário logado não possui licenças suficientes.",
            variant: 'danger',
            timeout: 5000
          },
        })
        router.push("/error-assinatura").catch(() => {});

        break;
      }
      case 403: {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro.',
            icon: 'LockIcon',
            text: "Ação não autorizada. \n" + errorMessage ,
            variant: 'danger',
            timeout: 10000
          },
        })
        break;
      }
      case 422: {
        let messageFinal = "";
        try {
          var validation_messages = JSON.parse(error.response.data);
        } catch (e) {
          var validation_messages = error.response.data;
        }
        if (Array.isArray(validation_messages) || typeof validation_messages === 'object' ) {
          for (var key in validation_messages) {
            var obj = validation_messages[key];
            console.error('obj', obj)
            if (typeof obj === 'string'){
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro',
                  icon: 'BellIcon',
                  text: obj,
                  variant: 'danger',
                  timeout: 5000
                },
              })
            }
          }
        } else {
            messageFinal = validation_messages;
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro',
                icon: 'BellIcon',
                text: messageFinal,
                variant: 'danger',
                timeout: 5000
              },
            })
        }
        messageFinal = "";
        break;
      }
      case 500: {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro',
            icon: 'BellIcon',
            text: 'Erro inesperado ): Por favor, tente novamente.',
            variant: 'danger',
            timeout: 5000,
          },
        })
        break;
      }
      default: {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro',
            icon: 'BellIcon',
            text: 'Erro inesperado ): Por favor, tente novamente.',
            variant: 'danger',
            timeout: 5000
          },
        })
        break;
      }
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns

export default axiosIns
