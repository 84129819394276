/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    profiles: [],
    permissions: [],
    permissions_profile: [],
    users: [],
  },
  getters: {
    getProfileById: (state) => (id) => {
      return state.profiles.find(p => p.id === id)
    },
    getPermissionById: (state) => (id) => {
      return state.permissions.find(p => p.id === id)
    },
    getPermissionsProfile: (state) => (profile_id) => {
      const index = state.profiles.findIndex((p) => p.id == profile_id)
      return state.profiles[index];
    }
  },
  mutations: {
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
    SET_PROFILES(state, profiles) {
      state.profiles = profiles
    },
    SET_USERS(state, users) {
      state.users = users
    },
    SET_PERMISSIONS_PROFILE(state, permissions_profile) {
      state.permissions_profile = permissions_profile
    },
  },
  actions: {
    fetchPermissions({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/permissions').then(({ data }) => {   
          commit('SET_PERMISSIONS', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchProfiles({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/profiles').then(({ data }) => {   
          commit('SET_PROFILES', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchPermissionsProfile({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/permission-profile').then(({ data }) => {   
          commit('SET_PERMISSIONS_PROFILE', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/permission-profile', data)
          .then((response) => {
            commit('SET_PERMISSIONS_PROFILE', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

  },
}
