import Vue from 'vue'
import Router from 'vue-router'
import { isSignedIn } from '@/auth'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(Router)

var dataUser = JSON.parse(localStorage.getItem('userData'));

if(dataUser == null){
  dataUser = 'teste'
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/Home.vue'),
    //   meta: {
    //     pageTitle: 'Dashboard',
    //     breadcrumb: [
    //       {
    //         text: 'Dashboard',
    //         active: true,
    //       },
    //     ],
    //     action: 'read',
    //     resource: 'home',
    //   },
    // },
    {
      path: '/fases',
      name: 'phases',
      component: () => import('@/views/phases/Index.vue'),
      meta: {
        pageTitle: 'Fases',
        breadcrumb: [
          {
            text: 'Fases',
            active: true,
          },
        ],
        action: 'read',
        resource: 'ADMIN',
      },
    },
    {
      path: '/modulos',
      name: 'modules',
      component: () => import('@/views/modules/Index.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Módulos',
        breadcrumb: [
          {
            text: 'Módulos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/empresas',
      name: 'companies',
      component: () => import('@/views/company/Index.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Empresas',
        breadcrumb: [
          {
            text: 'Empresas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/escolas',
      name: 'schools',
      component: () => import('@/views/school/Index.vue'),
      meta: {
        resource: 'SCHOOL_ADMIN',
        pageTitle: 'Escolas',
        breadcrumb: [
          {
            text: 'Escolas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/escola/:school_id',
      name: 'school-show',
      component: () => import('@/views/school/ShowSchool.vue'),
      meta: {
        resource: 'SCHOOL_ADMIN',
        pageTitle: 'Dados da escola',
        breadcrumb: [
          {
            to: '/escolas',
            text: 'Escolas',
            active: false,
          },
          {
            text: 'Dados da escola',
            active: true,
          },
        ],
      },
    },
    {
      path: '/turma/:class_id',
      name: 'classes-detail',
      component: () => import('@/views/classes/ShowClass.vue'),
      meta: {
        resource: 'SCHOOL_ADMIN',
        pageTitle: 'Detalhes da turma',
        breadcrumb: [
          {
            to: '/escolas',
            text: 'Escolas',
            active: false,
          },
          {
            to: {name: 'school-show'},
            text: 'Dados da escola',
            active: false,
          },
          {
            text: 'Detalhes da turma',
            active: true,
          },
        ],
      },
    },
    {
      path: '/diretores',
      name: 'directors',
      component: () => import('@/views/directors/Index.vue'),
      meta: {
        resource: 'SCHOOL_ADMIN',
        pageTitle: 'Diretores',
        breadcrumb: [
          {
            text: 'Diretores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/assinaturas',
      name: 'contracts',
      component: () => import('@/views/contract/Index.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Assinaturas',
        breadcrumb: [
          {
            text: 'Assinaturas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/empresa/:company_id',
      name: 'company-show',
      component: () => import('@/views/company/ShowCompany.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Assinaturas da empresa',
        breadcrumb: [
          {
            to: '/empresas',
            text: 'Empresas',
            active: false,
          },
          {
            text: 'Assinaturas da empresa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/minhas-assinaturas',
      name: 'contracts-by-company',
      component: () => import('@/views/company/ShowCompany.vue'),
      meta: {
        resource: 'COMPANY_ADMIN',
        pageTitle: 'Minhas Assinaturas',
        breadcrumb: [
          {
            text: 'Minhas Assinaturas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contract/:contract_id',
      name: 'contract-show',
      component: () => import('@/views/contract/ShowContract.vue'),
      meta: {
        resource: 'COMPANY_ADMIN',
        pageTitle: 'Detalhes da Assinaturas',
        breadcrumb: [
          {
            to: '/empresas',
            text: 'Empresas',
            active: false,
          },
          {
            to: {name: 'company-show'},
            text: 'Assinaturas da empresa',
            active: false,
          },
          {
            text: 'Detalhes da Assinaturas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/livros',
      name: 'books',
      component: () => import('@/views/book/Index.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Caderno de atividades',
        breadcrumb: [
          {
            text: 'Caderno de atividades',
            active: true,
          },
        ],
      },
    },
    {
      path: '/habilidades',
      name: 'abilities',
      component: () => import('@/views/bncc/Abilities.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Habilidades',
        breadcrumb: [
          {
            text: 'Habilidades',
            active: true,
          },
        ],
      },
    },
    {
      path: '/competencias',
      name: 'competences',
      component: () => import('@/views/bncc/Competences.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Competências',
        breadcrumb: [
          {
            text: 'Competências',
            active: true,
          },
        ],
      },
    },
    /* {
      path: '/cadernos-professor',
      name: 'teacher-books',
      component: () => import('@/views/teacher/book/Index.vue'),
      meta: {
        resource: 'SCHOOL_ADMIN',
        pageTitle: 'Cadernos de atividades',
        breadcrumb: [
          {
            text: 'Cadernos de atividades',
            active: true,
          },
        ],
      },
    }, */
    {
      path: '/livro/detalhe/:id_book',
      name: 'books-detail',
      component: () => import('@/views/book/ShowBook.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Detalhes do livro',
        breadcrumb: [
          {
            to: '/livros',
            text: 'Caderno de atividades',
            active: false,
          },
          {
            text: 'Detalhes do livro',
            active: true,
          },
        ],
      },
    },
    {
      path: '/livro/aula/:id_lesson',
      name: 'books-lesson',
      component: () => import('@/views/book/ShowLesson.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Aula',
        breadcrumb: [
          {
            to: '/livros',
            text: 'Caderno de atividades',
            active: false,
          },
          {
            to: {name: 'books-detail'},
            text: 'Detalhes do livro',
            active: false,
          },
          {
            text: 'Aula',
            active: true,
          },
        ],
      },
    },
    {
      path: '/livros/novo',
      name: 'books-create',
      component: () => import('@/views/book/Create.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Novo',
        breadcrumb: [
          {text: 'Caderno de atividades'},
          {
            text: 'Novo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios',
      name: 'users',
      component: () => import('@/views/user/Index.vue'),
      meta: {
        resource: 'ADMIN',
        pageTitle: 'Usuários',
        breadcrumb: [
          {
            text: 'Usuários',
            active: true,
          },
        ],
      },
    },
    {
      path: '/perfil',
      name: 'profile',
      component: () => import('@/views/profile/Index.vue'),
      meta: {
        resource: 'SCHOOL_ADMIN',
        pageTitle: 'Perfil',
        breadcrumb: [
          {
            text: 'Perfil',
            active: true,
          },
        ],
      },
    },
    {
      path: '/notificacoes',
      name: 'notifications',
      component: () => import('@/views/notifications/Index.vue'),
      meta: {
        pageTitle: 'Central de Notificações',
        action: 'read',
        resource: 'home',
        breadcrumb: [
          {
            text: 'Central de Notificações',
            active: true,
          },
        ],
      },
    },
    {
      path: '/livro/summary-professor/:book_id',
      name: 'book-details-class',
      component: () => import('@/views/teacher/book/Summary.vue'),
      meta: {
        pageTitle: 'Livro',
        action: 'read',
        resource: 'home',
        breadcrumb: [
          {
            text: 'Livro',
            active: true,
          },
        ],
      },
    },
    {
      path: '/livro/aula-detalhe/:lesson_id',
      name: 'lesson-details-class',
      component: () => import('@/views/teacher/book/Show.vue'),
      meta: {
        pageTitle: 'Aula',
        action: 'read',
        resource: 'home',
        breadcrumb: [
          {
            text: 'Livro',
            active: false,
          },
          {
            text: 'Aula',
            active: true,
          },
        ],
      },
    },
    {
      path: '/mural',
      name: 'murals',
      component: () => import('@/components/dashboard/student/Mural.vue'),
      meta: {
        pageTitle: 'Mural',
        action: 'read',
        resource: 'home',
        breadcrumb: [
          {
            text: 'Mural',
            active: true,
          },
        ],
      },
    },
    {
      path: '/mural/:mural_id',
      name: 'mural-show',
      component: () => import('@/components/dashboard/student/ShowMural.vue'),
      meta: {
        pageTitle: 'Mural',
        action: 'read',
        resource: 'home',
        breadcrumb: [
          {
            text: 'Mural',
            active: true,
          },
        ],
      },
    },
    {
      path: '/recuperacao',
      name: 'forgotPassword',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/pages/not-authorized',
      name: 'not-authorized',
      // ! Update import path
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/error-assinatura',
      name: 'no-license',
      // ! Update import path
      component: () => import('@/views/error/NoLicense.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/api-off-line',
      name: 'api-off-line',
      component: () => import('@/views/error/ErrorApiOffLine.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    }
  ]
})

router.beforeEach((to, from, next) => {
  
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
