/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    supportMaterials: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getSupportMaterialsById: (state) => (id) => {
      return state.supportMaterials.find(supportMaterial => supportMaterial.id === id)
    }
  },
  mutations: {
    SET_SUPPORT_MATERIALS(state, supportMaterials) {
      state.supportMaterials = supportMaterials
    },
    ADD_SUPPORT_MATERIAL(state, item){
      state.supportMaterials.unshift(item)
    },
    UPDATE_SUPPORT_MATERIAL(state, supportMaterial) {
      const supportMaterialIndex = state.supportMaterials.findIndex((p) => p.id == supportMaterial.data.id)
      Object.assign(state.supportMaterials[supportMaterialIndex], supportMaterial.data)
    },
    REMOVE_SUPPORT_MATERIAL(state, id) {
      const supportMaterialIndex = state.supportMaterials.findIndex((p) => p.id == id)
      state.supportMaterials.splice(supportMaterialIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchSupportMaterials({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/support-materials', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_SUPPORT_MATERIALS', data)
          } else {
            commit('SET_SUPPORT_MATERIALS', data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/support-materials/${args.support_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_SUPPORT_MATERIALS', data.data)
          } else {
            commit('SET_SUPPORT_MATERIALS', data)
          }
          commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.file != null)
          formData.append("file", data.file );
        formData.append("name", data.name );
        formData.append("description", data.description );
        if(data.link != null)
          formData.append("url", data.link );
        axios.post('/support-materials',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_SUPPORT_MATERIAL', response.data)
          resolve(response.data)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.data.file != null){
          formData.append("file", data.data.file);
        }
        formData.append("name", data.data.name);
        formData.append("description", data.data.description);
        if(data.data.link != null){
          formData.append("url", data.data.link);
        }
        axios.post(`/support-materials/${data.data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
            context.commit('UPDATE_SUPPORT_MATERIAL', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/support-materials/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_SUPPORT_MATERIAL', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
