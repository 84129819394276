/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    modules: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getModulesById: (state) => (id) => {
      return state.modules.find(module => module.id === id)
    }
  },
  mutations: {
    SET_MODULES(state, modules) {
      state.modules = modules
    },
    ADD_MODULE(state, item){
      state.modules.unshift(item)
    },
    UPDATE_MODULE(state, module) {
      const moduleIndex = state.modules.findIndex((p) => p.id == module.data.id)
      Object.assign(state.modules[moduleIndex], module.data)
    },
    REMOVE_MODULE(state, id) {
      const moduleIndex = state.modules.findIndex((p) => p.id == id)
      state.modules.splice(moduleIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchModules({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/modules', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_MODULES', data)
          } else {
            commit('SET_MODULES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/modules/${args.module_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_MODULES', data.data)
          } else {
            commit('SET_MODULES', data)
          }
          commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("icon", data.icon );
        formData.append("name", data.name );
        formData.append("color", data.color );
        axios.post('/modules',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_MODULE', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.icon){
          formData.append("icon", data.icon );
        }
        formData.append("name", data.name );
        formData.append("color", data.color );
        axios.post(`/modules/${data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
            context.commit('UPDATE_MODULE', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/modules/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_MODULE', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
