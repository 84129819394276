/* eslint-disable */
import axios from "@axios"
import axiosAPI from 'axios'

export default {
  namespaced: true,
  state: {
    schools: [],
    schoolsInep: [],
    schoolShow: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getSchoolById: (state) => (id) => {
      return state.schools.find(schools => schools.id === id)
    },
    getSchoolAdminById: (state) => (id) => {
      return state.schoolShow.users.find(users => users.id === id)
    },
    getSchoolTeacherById: (state) => (id) => {
      return state.schoolShow.teachers.find(teachers => teachers.id === id)
    },
    getClassById: (state) => (id) => {
      return state.schoolShow.classes.find(classes => classes.id === id)
    },
  },
  mutations: {
    SET_SCHOOLS(state, schools) {
      state.schools = schools
    },
    SET_SCHOOL_SHOW(state, schools) {
      state.schoolShow = schools
    },
    SET_SCHOOL_INFO(state, schools) {
      state.schoolsInep = schools
    },
    ADD_SCHOOL(state, item){
      state.schools.unshift(item)
    },
    ADD_SCHOOL_ADMIN(state, item){
      state.schoolShow.users.unshift(item)
    },
    UPDATE_SCHOOL_ADMIN(state, school) {
      const schoolIndex = state.schoolShow.users.findIndex((p) => p.id == school.id)
      Object.assign(state.schoolShow.users[schoolIndex], school)
    },
    ADD_LICENSE(state, item){
      state.schools.unshift(item)
    },
    UPDATE_SCHOOL(state, school) {
      const schoolIndex = state.schools.findIndex((p) => p.id == school.id)
      Object.assign(state.schools[schoolIndex], school)
    },
    UPDATE_IDEB(state, ideb) {
      const idebIndex = state.schools.findIndex((p) => p.id == ideb.id)
      Object.assign(state.schools[idebIndex], ideb)
    },
    REMOVE_SCHOOL(state, id) {
      const schoolIndex = state.schools.findIndex((p) => p.id == id)
      state.schools.splice(schoolIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchSchools({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/schools', {
          params: args,
        }).then(({ data }) => {   
          if (args.paginate) {
            commit('SET_SCHOOLS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } else {
            commit('SET_SCHOOLS', data)
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/schools/${args.school_id}`, {
          params: args,
        }).then(({ data }) => {
          commit('SET_SCHOOL_SHOW', data)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    fetchSchoolsInep({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/school-by-inep/${data}`)
        .then(({ data }) => {
          commit('SET_SCHOOL_INFO', data)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    setLicense({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/set-licences', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_LICENSE', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/schools', data)
          .then((response) => {
            commit('ADD_SCHOOL', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    storeSchoolAdmin({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/school-admin-register', data)
          .then((response) => {
            commit('ADD_SCHOOL_ADMIN', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    updateSchoolAdmin(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/users/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_SCHOOL_ADMIN', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/schools/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_SCHOOL', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    updateIdeb(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/idebs`, data)
        .then((response) => {
            context.commit('UPDATE_IDEB', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/schools/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_SCHOOL', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
