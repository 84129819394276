/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    classTeacher: [],
    activityTeacher: [],
    showActivity: {},
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {

  },
  mutations: {
    SET_CLASS_TEACHER(state, classTeacher) {
      state.classTeacher = classTeacher
    },
    SET_ACTIVITY_TEACHER(state, activityTeacher) {
      state.activityTeacher = activityTeacher
    },
    SHOW_DETAILS_ACTIVITY(state, showActivity) {
      state.showActivity = showActivity
    },
    ADD_GRADE_ACTIVITY(state, item){
      state.showActivity.data[localStorage.getItem('IndexGrade')].grade = item
    },
    ADD_COMMENT(state, item) {
      state.showActivity.data[localStorage.getItem('IndexComments')].comments.push(item)
    },
  },
  actions: {
    fetchClassTeacher({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/class-teacher', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_CLASS_TEACHER', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchActivityTeacher({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/dashboard-teacher`, {params: data})
          .then((data) => {
            commit('SET_ACTIVITY_TEACHER', data.data)
            resolve(data.data)
          })
          .catch(err => { return reject(err) })
      })
    },

    fetchDetailsActivity({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/activity-student-by-teacher/${data.activity_class_lesson_id}`).then(({ data }) => {
          commit('SHOW_DETAILS_ACTIVITY', data)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    answerActivities({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/correct-activity-student/${localStorage.getItem('activityStudentId')}`, data)
          .then((response) => {
            commit('ADD_GRADE_ACTIVITY', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    addGrade(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/correct-activity-student/${data.id}`, data)
        .then((response) => {
            context.commit('ADD_GRADE_ACTIVITY', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    addComments({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/activity-comment', data)
          .then((response) => {
            commit('ADD_COMMENT', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

  },
}
