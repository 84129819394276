/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    lessonResults: [],
  },
  mutations: {
    SET_LESSON_RESULTS(state, lessonResults) {
      state.lessonResults = lessonResults
    },
    ADD_LESSON_RESULT(state, item){
      state.lessonResults.unshift(item)
    },
    REMOVE_LESSON_RESULT(state, id) {
      const lessonResultsIndex = state.lessonResults.findIndex((p) => p.id == id)
      state.lessonResults.splice(lessonResultsIndex, 1)
    },
  },
  actions: {
    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.type == 'FILE' || data.type == 'IMAGE' ){
          formData.append("file", data.file );
        }
        formData.append("name", data.name );
        formData.append("url", data.url );
        formData.append("type", data.type );
        formData.append("lesson_id", data.lesson_id);
        axios.post('/lesson-result',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_LESSON_RESULT', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },
    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/lesson-result/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_LESSON_RESULT', response.data.id) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 
  },
}
