/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    lessons: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getLessonsById: (state) => (id) => {
      return state.lessons.find(lesson => lesson.id === id)
    }
  },
  mutations: {
    SET_LESSONS(state, lessons) {
      state.lessons = lessons
    },
    ADD_LESSON(state, item){
      state.lessons.unshift(item)
    },
    SET_ORDER_LESSON(state, lesson) {
        state.lessons = lesson
    },
    UPDATE_LESSON(state, lesson) {
      const lessonIndex = state.lessons.findIndex((p) => p.id == lesson.data.id)
      Object.assign(state.lessons[lessonIndex], lesson.data)
    },
    ADD_LESSON_SUPPORT_MATERIAL(state, item){
      state.lessons.users.support_materials(item)
    },
    UPDATE_SUPPORT_MATERIAL(state, lesson){
      const lessonIndex = state.lessons.support_materials.findIndex((p) => p.id == lesson.id)
      Object.assign(state.lessons.support_materials[lessonIndex], lesson)
    },
    REMOVE_LESSON(state, id) {
      const lessonIndex = state.lessons.findIndex((p) => p.id == id)
      state.lessons.splice(lessonIndex, 1)
    },
    REMOVE_LESSON_SUPPORT_MATERIAL(state, lesson){
      const lessonIndex = state.lessons.support_materials.findIndex((p) => p.id == lesson.id)
      state.lessons.support_materials.splice(lessonIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchLessons({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/lessons', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_LESSONS', data)
          } else {
            commit('SET_LESSONS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/lessons/${args.lesson_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_LESSONS', data.data)
            commit('lessonResults/SET_LESSON_RESULTS', data.data.data.lessonResults, { root: true }) 
          } else {
            commit('SET_LESSONS', data)
            commit('lessonResults/SET_LESSON_RESULTS', data.lessonResults, { root: true }) 
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },


    reorderLessonActive({ commit }, args) {
        return new Promise((resolve, reject) => {
            axios.post(`/activity-reorder/${args.activity_id}`, {
             ...args,
            }).then(({ data }) => {
                if (args.paginated) {
                 commit('SET_ORDER_LESSON', data.data)

                } else {     commit('SET_ORDER_LESSON', data)
                }
                resolve(data)
            }).catch((error) => { reject(error) })

        })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/lessons', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_LESSON', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    storeSupportMaterial({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data.file );
        formData.append("name", data.name );
        formData.append("description", data.description );
        axios.post('/support-materials',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_LESSON_SUPPORT_MATERIAL', response.data)
          resolve(response.data)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/lessons/${data.id}`, {
          ...data
        })
        .then((response) => {
            context.commit('UPDATE_LESSON', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    updateSupportMaterial(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.icon != null){
          formData.append("file", data.data.file);
        }
        formData.append("name", data.data.name);
        formData.append("description", data.data.description);
        axios.post(`/support-materials/${data.data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
            context.commit('UPDATE_SUPPORT_MATERIAL', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/lessons/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_LESSON', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

    deleteSupportMaterial(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/support-materials/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_LESSON_SUPPORT_MATERIAL', response) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
