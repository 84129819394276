/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    teachers: [],
    teacherSchool: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getTeacherById: (state) => (id) => {
      return state.teachers.find(teacher => teacher.id === id)
    }
  },
  mutations: {
    SET_TEACHERS(state, teachers) {
      state.teachers = teachers
    },
    SET_TEACHER_SCHOOL(state, teacherSchool) {
      state.teacherSchool = teacherSchool
    },
    ADD_TEACHER(state, item){
      state.teachers.unshift(item)
    },
    UPDATE_TEACHER(state, teacher) {
      const teacherIndex = state.teachers.findIndex((p) => p.id == teacher.id)
      Object.assign(state.teachers[teacherIndex], teacher)
    },
    REMOVE_TEACHER(state, id) {
      const teacherIndex = state.teachers.findIndex((p) => p.id == id)
      state.teachers.splice(teacherIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchTeachers({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/teachers', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_TEACHERS', data)
          } else {
            commit('SET_TEACHERS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/teachers/${args.teacher_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_TEACHERS', data.data)
          } else {
            commit('SET_TEACHERS', data)
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    showTeacherSchool({commit}, data){
      return new Promise((resolve, reject) => {
        axios.get(`/teachers/${data.cpf_teacher}`,
        {params: data})
        .then(({data}) => {
          if(data !== 'Professor não encontrado'){
            commit('SET_TEACHER_SCHOOL', data.data)
          }
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    storeSchoolTeacher({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/school-teacher', data)
          .then((response) => {
            commit('SET_TEACHERS', response.data.teachers)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/teachers', data)
          .then((response) => {
            commit('ADD_TEACHER', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    import({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data.file );
        formData.append("school_id", data.school_id );
        axios.post('/teachers-import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/teachers/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_TEACHER', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, payload) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/school-teacher/`, { data: payload }) 
          .then((response) => { 
            context.commit('REMOVE_TEACHER', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
