/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    students: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
  },
  mutations: {
    SET_STUDENTS(state, students) {
      state.students = students
    },
    ADD_STUDENT(state, item){
      state.students.unshift(item)
    },
    UPDATE_STUDENT(state, student) {
      const studentIndex = state.students.findIndex((p) => p.id == student.id)
      Object.assign(state.students[studentIndex], student)
    },
    REMOVE_STUDENT(state, id) {
      const studentIndex = state.students.findIndex((p) => p.id == id)
      state.students.splice(studentIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchStudents({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/students', {
          params: args,
        }).then(({ data }) => {   
          if (args.paginate) {
            commit('SET_STUDENTS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } else {
            commit('SET_STUDENTS', data)
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/students', data)
          .then((response) => {
            commit('ADD_STUDENT', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/students/${data.id}`, data)
          .then((response) => {
            if (data.from = 'show_class') {
              context.commit('classes/UPDATE_STUDENT_CLASS', response.data, { root: true })
            } else {
              context.commit('UPDATE_STUDENT', response.data)
            }
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/students/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_STUDENT', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 
    import({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data.file );
        formData.append("class_id", data.class_id );
        axios.post('/students-import',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

  },
}
