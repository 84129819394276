/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    dashboard: [],
    idebEvolution: [],
  },
  mutations: {
    SET_DASHBOARD_ADMIN(state, dashboard) {
      state.dashboard = dashboard
    },
    SET_DASHBOARD_TEACHER(state, dashboard) {
      state.dashboard = dashboard
    },
    SET_DASHBOARD_SCHOOL_ADMIN(state, dashboard) {
      state.dashboard = dashboard
    },
    SET_DASHBOARD_COMPANY_ADMIN(state, dashboard) {
      state.dashboard = dashboard
    },
    SET_IDEB_EVOLUTION(state, idebEvolution) {
      state.idebEvolution = idebEvolution
    },
  },
  actions: {
    fetchDashboardAdmin({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/dashboard-admin', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_DASHBOARD_ADMIN', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDashboardSchoolAdmin({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/dashboard-school-admin', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_DASHBOARD_SCHOOL_ADMIN', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDashboardTeacher({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/dashboard-teacher-home', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_DASHBOARD_TEACHER', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDashboardCompanyAdmin({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/dashboard-company-admin', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_DASHBOARD_COMPANY_ADMIN', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchIdebEvolution({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/get-ideb-evolution/${args.school_id}`).then(({ data }) => {   
          commit('SET_IDEB_EVOLUTION', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
  },
}
