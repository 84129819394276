/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    books: [],
    booksList: [],
    book_lessons: [],
    book_detail: null,
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getBookById: (state) => (id) => {
      return state.books.find(book => book.id === id)
    },
    getShortBookById: (state) => (id) => {
      return state.booksList.find(book => book.id === id)
    }
  },
  mutations: {
    SET_BOOKS(state, books) {
      state.books = books
    },
    SET_BOOKS_LIST(state, books) {
      state.booksList = books
    },
    SET_BOOK_LESSONS(state, book_lessons) {
      state.book_lessons = book_lessons
    },
    SET_BOOK_DETAIL(state, book) {
      state.book_detail = book
    },
    SET_ORDER_LESSON(state, books) {
      state.books.lessons = books
    },
    ADD_BOOK(state, item){
      state.books.unshift(item)
    },
    COPY_BOOK(state, item){
      state.books.unshift(item)
    },
    UPDATE_BOOK(state, book) {
      const bookIndex = state.books.findIndex((p) => p.id == book.data.id)
      Object.assign(state.books[bookIndex], book.data)
    },
    REMOVE_BOOK(state, id) {
      const bookIndex = state.books.findIndex((p) => p.id == id)
      state.books.splice(bookIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchBooks({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/books', {
          params: args,
        }).then(({ data }) => {   
          if(args.paginate == false){
            commit('SET_BOOKS', data)
          } else {
            commit('SET_BOOKS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchBooksShort({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/books-short', {
          params: args,
        }).then(({ data }) => {   
          if(args.paginate == false){
            commit('SET_BOOKS_LIST', data)
          } else {
            commit('SET_BOOKS_LIST', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchBookLessons({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/list-book-with-lessons').then(({ data }) => { 
          commit('SET_BOOK_LESSONS', data)
          resolve(data)
        }).catch((error) => { 
          console.error(error) 
          reject(error) })
      })
    },
    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/books/${args.book_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_BOOK_DETAIL', data.data)
          } else {
            commit('SET_BOOK_DETAIL', data)
            // commit('SET_PAGINATION', {
            //   from: data.from,
            //   to: data.to,
            //   page: data.current_page,
            //   rowsPerPage: data.per_page,
            //   totalRows: data.total
            // })
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    reorderLessonBook({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.post(`/lesson-reorder/${args.lesson_id}`, {
          ...args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_ORDER_LESSON', data.data)
          } else {
            commit('SET_ORDER_LESSON', data)
            // commit('SET_PAGINATION', {
            //   from: data.from,
            //   to: data.to,
            //   page: data.current_page,
            //   rowsPerPage: data.per_page,
            //   totalRows: data.total
            // })
          }
          resolve(data)
        }).catch((error) => { reject(error) })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/books', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_BOOK', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    storeAssociated({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/books-link', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },
    storeWithExistingLessons({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/books-with-existing-lessons', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_BOOK', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },
    updateWithExistingLessons({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/books-with-existing-lessons/${data.book_id}`, data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('UPDATE_BOOK', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },
    copyBook({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/copy-book', data).then((response) => {
          commit('COPY_BOOK', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/books/${data.id}`, {
          ...data
        })
        .then((response) => {
            context.commit('UPDATE_BOOK', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/books/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_BOOK', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

    linkBooks(context, data){
      return new Promise((resolve, reject) => {
        axios.post('/books-link', data).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    }
  },
}
