/* eslint-disable */
import axios from "@axios"
import responsible from "../responsible";

export default {
  namespaced: true,
  state: {
    classes: [],
    classShow: {},
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },

  getters: {
    getClassById: (state) => (id) => {
      return state.classes.find(classes => classes.id === id)
    },
    getStudentById: (state) => (id) => {
      return state.classShow.students.find(student => student.id === id)
    },
    getResponsibleById: (state) => (id) => {
      let responsible = null
      state.classShow && state.classShow.students.map(student => {
        let resp = student.responsibles && student.responsibles.find(responsible => responsible.id === id)
        if (resp) {
          responsible = resp
        }
        return student;
      });
      return responsible
    },
  },
  mutations: {
    SET_CLASSES(state, classes) {
      state.classes = classes
    },
    SET_CLASS_SHOW(state, classes) {
      state.classShow = classes
    },
    SET_CLASS_INFO(state, classes) {
      state.classesInep = classes
    },
    ADD_CLASS(state, item){
      state.classes.unshift(item)
    },
    ADD_LICENSE(state, item){
      state.classes.unshift(item)
    },
    UPDATE_CLASS(state, classe) {
      const classIndex = state.classes.findIndex((p) => p.id == classe.id)
      Object.assign(state.classes[classIndex], classe)
    },
    UPDATE_STUDENT_CLASS(state, student) {
      const classIndex = state.classShow.students.findIndex((p) => p.id == student.id)
      Object.assign(state.classShow.students[classIndex], student)
    },
    UPDATE_IDEB(state, ideb) {
      const idebIndex = state.classes.findIndex((p) => p.id == ideb.id)
      Object.assign(state.classes[idebIndex], ideb)
    },
    REMOVE_CLASS(state, id) {
      const classIndex = state.classes.findIndex((p) => p.id == id)
      state.classes.splice(classIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchClasses({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/classes', {
          params: args,
        }).then(({ data }) => {   
          if (args.paginate) {
            commit('SET_CLASSES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } else {
            commit('SET_CLASSES', data)
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchClassesTeacher({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/class-teacher', {
          params: args,
        }).then(({ data }) => {   
          
          commit('SET_CLASSES', data)
          
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetailsClass({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/classes/${args.class_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_CLASS_SHOW', data.data)
          } else {
            commit('SET_CLASS_SHOW', data)
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/classes', data)
          .then((response) => {
            commit('ADD_CLASS', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/classes/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_CLASS', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },
    setDefaultBook(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/set-active-book`, data)
        .then((response) => {
            
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },
    

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/classes/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_CLASS', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 
 
    import({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data.file );
        formData.append("school_id", data.school_id );
        axios.post('/classes-import',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

  },
}
