/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    companies: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getCompanyById: (state) => (id) => {
      return state.companies.find(company => company.id === id)
    }
  },
  mutations: {
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    ADD_COMPANY(state, item){
      state.companies.unshift(item)
    },
    ADD_COMPANY_ADMIN(state, item){
      state.companies.users.unshift(item)
    },
    UPDATE_COMPANY_ADMIN(state, company) {
      const companyIndex = state.companies.findIndex((p) => p.id == company.id)
      Object.assign(state.companies[companyIndex], company)
    },
    REMOVE_COMPANY_ADMIN(state, id) {
      const companyIndex = state.companies.users.findIndex((p) => p.id == id)
      state.companies.users.splice(companyIndex, 1)
    },
    UPDATE_COMPANY(state, company) {
      const companyIndex = state.companies.findIndex((p) => p.id == company.id)
      Object.assign(state.companies[companyIndex], company)
    },
    REMOVE_COMPANY(state, id) {
      const companyIndex = state.companies.findIndex((p) => p.id == id)
      state.companies.splice(companyIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchCompanies({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/companies', {
          params: args,
        }).then(({ data }) => {   
          if (args.paginate && args.paginate == true) {
            commit('SET_COMPANIES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } else {
            commit('SET_COMPANIES', data)
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/companies/${args.company_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_COMPANIES', data.data)
          } else {
            commit('SET_COMPANIES', data)
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/companies', data)
          .then((response) => {
            commit('ADD_COMPANY', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    storeCompanyAdmin({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/company-admin-register', data)
          .then((response) => {
            commit('ADD_COMPANY_ADMIN', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    updateCompanyAdmin(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/users/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_COMPANY_ADMIN', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/companies/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_COMPANY', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/companies/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_COMPANY', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

    deleteCompanyAdmin(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/users/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_COMPANY_ADMIN', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
