/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    activityStudent: [],
    activityStudentShow: [],
    activityStudentComments: [],
    activityStudentAnswerActivities: [],
  },
  getters: {
  },
  mutations: {
    SET_ACTIVITY_STUDENT(state, activityStudent) {
      state.activityStudent = activityStudent
    },
    SHOW_ACTIVITY_STUDENT(state, activityStudentShow) {
      state.activityStudentShow = activityStudentShow
    },
    ADD_COMMENT(state, item) {
      state.activityStudentShow.comments.push(item)
    },
    ADD_COMMENT_PUBLIC(state, item) {
      state.activityStudentShow.activity_class_lesson.comments.push(item)
    },
    ADD_ANSWER_ACTIVITY(state, item) {
      state.activityStudentShow.response = item.response
    },
  },
  actions: {
    fetchActivityStudent({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/dashboard-student`, {
          params: args,
        }).then(({ data }) => {   

          commit('SET_ACTIVITY_STUDENT', data)
          
          // if(data.activities.length > 0) {
          //   localStorage.removeItem('classId')
          //   localStorage.setItem(
          //     "classId", data.activities[0].class_id
          //   )
          // }
          
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/activity-student-show/${localStorage.getItem('activityStudentId')}`).then(({ data }) => {
          commit('SHOW_ACTIVITY_STUDENT', data)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },
    
    addComments({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/activity-comment', data)
          .then((response) => {
            commit('ADD_COMMENT', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    addCommentsPublic({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/class-comments', data)
          .then((response) => {
            commit('ADD_COMMENT_PUBLIC', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    answerActivities({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/solve-activity-student/${localStorage.getItem('activityStudentId')}`, data)
          .then((response) => {
            commit('ADD_ANSWER_ACTIVITY', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },


  },
}
