/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    classLessons: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getClassLessonsById: (state) => (id) => {
      return state.classLessons.find(classLesson => classLesson.id === id)
    }
  },
  mutations: {
    SET_CLASS_LESSONS(state, classLesson) {
      state.classLessons = classLesson
    },
    ADD_CLASS_LESSON(state, item){
      state.classLessons.unshift(item)
    },
    UPDATE_CLASS_LESSON(state, lesson) {
      const classLessonIndex = state.classLessons.findIndex((p) => p.id == classLesson.data.id)
      Object.assign(state.classLessons[classLessonIndex], classLesson.data)
    },
    REMOVE_CLASS_LESSON(state, id) {
      const classLessonIndex = state.classLessons.findIndex((p) => p.id == id)
      state.classLessons.splice(classLessonIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchClassLessons({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/class-lesson/${args.class_id}`, {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_CLASS_LESSONS', data)
          } else {
            commit('SET_CLASS_LESSONS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/class-lesson', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_CLASS_LESSON', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },
    reprocess({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/class-lesson-reprocess/${data.id}`).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },
    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete('class-lesson', { data: data})
        .then((response) => { 
          context.commit('REMOVE_CLASS_LESSON', response.data) 
          resolve(response) 
        }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
