/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    responseType: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getResponseTypeById: (state) => (id) => {
      return state.responseTypes.find(responseType => responseType.id === id)
    }
  },
  mutations: {
    SET_RESPONSE_TYPES(state, responseTypes) {
      state.responseTypes = responseTypes
    },
  },
  actions: {
    fetchResponseType({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/response-types', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_RESPONSE_TYPES', data.data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

  },
}
