/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    compentences: []
  },
  getters: {
    getCompentenceById: (state) => (id) => {
      return state.compentences.find(compentence => compentence.id === id)
    },
    getCompentenceDataById: (state) => (id) => {
      return state.compentences.data.find(compentence => compentence.id === id)
    }
  },
  mutations: {
    SET_COMPENTENCE(state, compentences) {
      state.compentences = compentences
    },
    UPDATE_COMPENTENCE(state, competences) {
      const competencesIndex = state.compentences.findIndex((p) => p.id == competences.id)
      Object.assign(state.compentences[competencesIndex], competences)
    },
    REMOVE_COMPENTENCE(state, id) {
      const competencesIndex = state.compentences.findIndex((p) => p.id == id)
      state.compentences.splice(competencesIndex, 1)
    },
  },
  actions: {
    fetchCompentence({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/competences', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_COMPENTENCE', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    import({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data );
        axios.post('/competences', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/competences/${data.id}`, data)
          .then((response) => {
            context.commit('UPDATE_COMPENTENCE', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/competences/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_COMPENTENCE', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
