/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    directors: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getDirectorById: (state) => (id) => {
      return state.directors.find(directors => directors.id === id)
    }
  },
  mutations: {
    SET_DIRECTORS(state, directors) {
      state.directors = directors
    },
    SET_DIRECTOR_SHOW(state, directors) {
      state.directorShow = directors
    },
    ADD_DIRECTOR(state, item){
      state.directors.unshift(item)
    },
    UPDATE_DIRECTOR(state, director) {
      const directorIndex = state.directors.findIndex((p) => p.id == director.id)
      Object.assign(state.directors[directorIndex], director)
    },
    REMOVE_DIRECTOR(state, id) {
      const directorIndex = state.directors.findIndex((p) => p.id == id)
      state.directors.splice(directorIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchDirectors({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/directors', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_DIRECTORS', data)
          } else {
            commit('SET_DIRECTORS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/directors/${args.director_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_DIRECTORS', data.data)
          } else {
            commit('SET_DIRECTORS', data)
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    import({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data.file );
        formData.append("company_id", data.company_id );
        axios.post('/schools-import',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/directors', data)
          .then((response) => {
            commit('ADD_DIRECTOR', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/directors/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_DIRECTOR', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/directors/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_DIRECTOR', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
