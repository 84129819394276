/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    notifications: [],
    count: null,
  },
  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
    SET_NOTIFICATION_COUNT(state, count) {
      state.count = count
    },
    ADD_NOTIFICATION(state, item){
      state.notifications.unshift(item)
    },
  },
  actions: {
    fetchNotifications({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/messages', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_NOTIFICATIONS', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchCountNotification({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/messages', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_NOTIFICATION_COUNT', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/messages/${args.notification_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_NOTIFICATIONS', data.data)
          } else {
            commit('SET_NOTIFICATIONS', data)
          }
          commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(
          '/messages', data
        ).then((response) => {
          commit('ADD_NOTIFICATION', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

  },
}
