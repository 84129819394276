import router from "@/router";
import axios from "@axios";
import moment from "moment";
import { initialAbility } from '@/libs/acl/config'

export default {
	namespaced: true,
	state: {
		users: {},
		totalPages: 0,
	},
	getters: {},
	mutations: {
		SET_BEARER(state, accessToken) {
			localStorage.removeItem(["accessToken", "expiresIn", "refreshToken"]);
			axios.defaults.headers.Authorization = "Bearer " + accessToken;
		},
		UPDATE_USER_INFO(state, users){
			state.users = users
		}
	},
	actions: {
		login({ commit }, payload) {
			const requestBody = {
				username: payload.userDetails.username,
				password: payload.userDetails.password
			}
			return new Promise((resolve, reject) => {
				localStorage.removeItem([
					"accessToken",
					"expiresIn"
				]);
				
				axios.post("/login", requestBody).then(response => {
					if (response.data.access_token) {
					localStorage.setItem(
						"accessToken",
						response.data.access_token
					);
					localStorage.setItem(
						"expiresIn", response.data.expires_in
					);
					
					commit("SET_BEARER", response.data.access_token);
					}
					if (response.data.user) {
						commit("UPDATE_USER_INFO", response.data.user);
						localStorage.setItem('userData', JSON.stringify(response.data.user))
					}
					
								
					resolve(response);
				}).catch(() => {
					return reject({
						message: "Algo deu errado, por favor tente novamente."
					});
				});
			});
		},

		loginByGoogle({ commit }, payload) {
			const requestBody = {
				email: payload.userDetails.email,
			}
			return new Promise((resolve, reject) => {
				localStorage.removeItem([
					"accessToken",
					"expiresIn"
				]);
				
				axios.post("/social-login", requestBody).then(response => {
					if (response.data.access_token) {
					localStorage.setItem(
						"accessToken",
						response.data.access_token
					);
					localStorage.setItem(
						"expiresIn", response.data.expires_in
					);
					
					commit("SET_BEARER", response.data.access_token);
					}
					if (response.data.user) {
						commit("UPDATE_USER_INFO", response.data.user);
						localStorage.setItem('userData', JSON.stringify(response.data.user))
					}
					
								
					resolve(response);
				}).catch(() => {
					return reject({
						message: "Algo deu errado, por favor tente novamente."
					});
				});
			});
		},

		newPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/change-password', data)
          .then((response) => {
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

		newPasswordByAdmin({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/change-password-by-admin', data)
          .then((response) => {
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

		sendMails({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/password/email', data)
          .then((response) => {
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

		newPasswordByToken({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/password/reset', data)
          .then((response) => {
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

	}
};
