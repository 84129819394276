/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    contracts: [],
    company_contracts: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getContractById: (state) => (id) => {
      return state.contracts.find(phase => phase.id === id)
    }
  },
  mutations: {
    SET_CONTRACTS(state, contracts) {
      state.contracts = contracts
    },
    SET_COMPANY_CONTRACTS(state, data) {
      state.company_contracts = data
    },
    ADD_CONTRACT(state, item){
      state.contracts.unshift(item)
    },
    UPDATE_CONTRACT(state, contract) {
      const contractIndex = state.contracts.findIndex((p) => p.id == contract.id)
      Object.assign(state.contracts[contractIndex], contract)
    },
    REMOVE_CONTRACT(state, id) {
      const contractIndex = state.contracts.findIndex((p) => p.id == id)
      state.contracts.splice(contractIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchContracts({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/contracts', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_CONTRACTS', data)
          } else {
            commit('SET_CONTRACTS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/contracts/${args.contract_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_CONTRACTS', data.data)
          } else {
            commit('SET_CONTRACTS', data)
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    fetchContractsByCompany({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.post(`/contracts-by-company`, {
          ...args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_COMPANY_CONTRACTS', data.data)
          } else {
            commit('SET_COMPANY_CONTRACTS', data)
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/contracts', data)
          .then((response) => {
            commit('ADD_CONTRACT', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/contracts/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_CONTRACT', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/contracts/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_CONTRACT', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
