/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    phases: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getPhasesById: (state) => (id) => {
      return state.phases.find(phase => phase.id === id)
    }
  },
  mutations: {
    SET_PHASES(state, phases) {
      state.phases = phases
    },
    ADD_PHASE(state, item){
      state.phases.unshift(item)
    },
    UPDATE_PHASE(state, phase) {
      const phaseIndex = state.phases.findIndex((p) => p.id == phase.data.id)
      Object.assign(state.phases[phaseIndex], phase.data)
    },
    REMOVE_PHASE(state, id) {
      const phaseIndex = state.phases.findIndex((p) => p.id == id)
      state.phases.splice(phaseIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchPhases({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/phases', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_PHASES', data)
          } else {
            commit('SET_PHASES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/phases/${args.phases_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_PHASES', data.data)
          } else {
            commit('SET_PHASES', data)
          }
          commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("icon", data.icon );
        formData.append("name", data.name );
        formData.append("color", data.color );
        axios.post('/phases',formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_PHASE', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.icon){
          formData.append("icon", data.icon );
        }
        formData.append("name", data.name );
        formData.append("color", data.color );
        axios.post(`/phases/${data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
            context.commit('UPDATE_PHASE', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/phases/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_PHASE', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
