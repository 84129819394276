/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    responsibles: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
  },
  mutations: {
    SET_RESPONSIBLES(state, responsibles) {
      state.responsibles = responsibles
    },
    ADD_RESPONSIBLE(state, item){
      state.responsibles.unshift(item)
    },
    UPDATE_RESPONSIBLE(state, responsible) {
      const responsibleIndex = state.responsibles.findIndex((p) => p.id == responsible.id)
      Object.assign(state.responsibles[responsibleIndex], responsible)
    },
    REMOVE_RESPONSIBLE(state, id) {
      const responsibleIndex = state.responsibles.findIndex((p) => p.id == id)
      state.responsibles.splice(responsibleIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchResponsibles({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/responsibles', {
          params: args,
        }).then(({ data }) => {   
          if (args.paginate) {
            commit('SET_RESPONSIBLES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } else {
            commit('SET_RESPONSIBLES', data)
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/responsibles', data)
          .then((response) => {
            commit('ADD_RESPONSIBLE', response.data)
            resolve(response)
          })
          .catch(err => { return reject(err) })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/responsibles/${data.id}`, data)
          .then((response) => {
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/responsibles/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_RESPONSIBLE', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
