import Vue from 'vue'
import { 
  ToastPlugin, 
  ModalPlugin, 
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import moment from 'moment'
import VueTheMask from 'vue-the-mask'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'

// Sentry
//import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)

//Mask plugin
Vue.use(VueTheMask)

// Sentry Plugin Registration
//Vue.use(Sentry)
//Vue.use(Integrations)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '331625773856-1cb60ttlquggsie8ba9rhifglt6e01s6.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
}
Vue.use(GAuth, gauthOption)

// Sentry config
/*Sentry.init({
  Vue,
  dsn: "https://7b0b646768ba4e64b04c793b851a951d@o877594.ingest.sentry.io/6033985",
  /* integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "https://app-dev.edubot.com.br/", /^\//],
    }),
  ],
  tracesSampleRate: 1.0, 
})*/


Vue.filter('dateFormat', function (valor) {
  if (valor && valor.length > 0) {
    return moment(String(valor)).format('DD/MM/YYYY')
  }
})

Vue.filter('dateTimeFormat', function (valor) {
  return moment(String(valor)).format('DD/MM/YYYY HH:mm')
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
