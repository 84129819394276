/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    murals: [],
    muralPublic: [],
    muralShow: [],
    linkPublic: null,
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  mutations: {
    SET_MURALS(state, murals) {
      state.murals = murals
    },
    SET_MURAL_PUBLIC(state, muralPublic) {
      state.muralPublic = muralPublic
    },
    SET_MURAL_SHOW(state, muralShow) {
      state.muralShow = muralShow
    },
    ADD_MURAL(state, item){
      state.murals.unshift(item)
    },
    REMOVE_MURAL(state, response) {
      const index = state.murals.findIndex((p) => p.id == response.id)
      state.murals.splice(index, 1)
    },
    UPDATE_MURAL(state, response) {
      const index = state.murals.findIndex((p) => p.id == response.id)
      Object.assign(state.murals[index], response)
    },
    ADD_LINK(state, item){
      state.linkPublic = item
    },
    ADD_COMMENTS_MURAL(state, item){
      state.muralShow.comments.push(item)
    },
    REMOVE_MURAL_COMMENTS(state, response) {
      const commentsIndex = state.muralShow.comments.findIndex((p) => p.id == response.id)
      state.muralShow.comments.splice(commentsIndex, 1)
    },
    UPDATE_MURAL_COMMENTS(state, response) {
      const commentsIndex = state.muralShow.comments.findIndex((p) => p.id == response.id)
      Object.assign(state.muralShow.comments[commentsIndex], response)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchMurals({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/murals', {
          params: args,
        }).then(({ data }) => {
          if(args.paginate == false){
            commit('SET_MURALS', data)
          } else {
            commit('SET_MURALS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          }
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/murals/${localStorage.getItem('mural_id')}`, {
          params: args,
        }).then(({ data }) => {
          commit('SET_MURAL_SHOW', data)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    fetchDetailsPublic({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/show-public-link/${args.mural_public_id}`, {
          params: args,
        }).then(({ data }) => {
          commit('SET_MURAL_PUBLIC', data)
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(
          '/murals', data
        ).then((response) => {
          commit('ADD_MURAL', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete( `/murals/${data.id}`) 
          .then((response) => {
            context.commit('REMOVE_MURAL', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/murals/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_MURAL', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    storeLink({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(
          '/create-public-link', data
        ).then((response) => {
          commit('ADD_LINK', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    addComments({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(
          `/murals/${data.mural_id}/comments`, data
        ).then((response) => {
          commit('ADD_COMMENTS_MURAL', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    deleteComments(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete( `/murals/${data.mural_id}/comments/${data.comments_id}`) 
          .then((response) => {
            context.commit('REMOVE_MURAL_COMMENTS', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    },

    updateComments(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/murals/${data.mural_id}/comments/${data.comments_id}`, data)
        .then((response) => {
            context.commit('UPDATE_MURAL_COMMENTS', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

  },
}
