/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    abilities: []
  },
  getters: {
    getAbilityById: (state) => (id) => {
      return state.abilities.find(ability => ability.id === id);
    },
    getAbilityDataById: (state) => (id) => {
      return state.abilities.data.find(ability => ability.id === id);
    }
  },
  mutations: {
    SET_ABILITIES(state, abilities) {
      state.abilities = abilities
    },
    UPDATE_ABILITY(state, ability) {
      const abilityIndex = state.abilities.findIndex((p) => p.id == ability.id)
      Object.assign(state.abilities[abilityIndex], ability)
    },
    REMOVE_ABILITY(state, id) {
      const abilityndex = state.abilities.findIndex((p) => p.id == id)
      state.abilities.splice(abilityndex, 1)
    },
  },
  actions: {
    fetchAbility({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/abilities', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_ABILITIES', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    import({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", data );
        axios.post('/abilities', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/abilities/${data.id}`, data)
          .then((response) => {
            context.commit('UPDATE_ABILITY', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/abilities/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_ABILITY', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
