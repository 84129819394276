/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    activities: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getActivitiesById: (state) => (id) => {
      return state.activities.find(activity => activity.id === id)
    }
  },
  mutations: {
    SET_ACTIVITIES(state, activities) {
      state.activities = activities
    },
    ADD_ACTIVITY(state, item){
      state.activities.unshift(item)
    },
    ADD_ACTIVITY_CUSTOM(state, item){
      state.activities.unshift(item)
    },
    UPDATE_ACTIVITY(state, activity) {
      const activityIndex = state.activities.findIndex((p) => p.id == activity.data.id)
      Object.assign(state.activities[activityIndex], activity.data)
    },
    REMOVE_ACTIVITY(state, id) {
      const activityIndex = state.activities.findIndex((p) => p.id == id)
      state.activities.splice(activityIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchActivities({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/activities', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_ACTIVITIES', data)
          } else {
            commit('SET_ACTIVITIES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/activities/${args.activity_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_ACTIVITIES', data.data)
          } else {
            commit('SET_ACTIVITIES', data)
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/activities', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_ACTIVITY', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    storeCustom({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/custom-activity', data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_ACTIVITY_CUSTOM', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/activities/${data.id}`, {
          ...data
        })
        .then((response) => {
            context.commit('UPDATE_ACTIVITY', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/activities/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_ACTIVITY', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
