/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    settings: [],
  },
  mutations: {
    SET_NOTIFICATIONS(state, settings) {
      state.settings = settings
    },
    UPDATE_NOTIFICATION(state, item){
      state.settings = item
    }
  },
  actions: {
    fetchNotifications({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/settings', {
          params: args,
        }).then(({ data }) => {   
          commit('SET_NOTIFICATIONS', data)
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(
          '/settings', data
        ).then((response) => {
          commit('UPDATE_NOTIFICATION', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

  },
}
