import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import modules from './module'
import auth from './auth'
import phases from './phase'
import books from './book'
import responseType from './responseType'
import supportMaterials from './supportMaterial'
import compentences from './compentence'
import abilities from './ability'
import activities from './activities'
import lessons from './lessons'
import contracts from './contract'
import companies from './company'
import schools from './school'
import acl from './acl'
import users from './user'
import directors from './director'
import classes from './class'
import students from './student'
import responsibles from './responsible'
import classLessons from './classLesson'
import teachers from './teacher'
import notifications from './notification'
import settings from './setting'
import murals from './murals'
import lessonResults from './lessonResult'

// Dashboard

import dashboard from './dashboard'

// Student Home

import activityStudent from './studentActivities/activityStudent.js'

// Teacher Home

import classTeacher from './teacherDashboard/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    modules,
    auth,
    phases,
    books,
    responseType,
    supportMaterials,
    compentences,
    abilities,
    contracts,
    companies,
    activities,
    lessons,
    schools,
    acl,
    users,
    directors,
    classes,
    students,
    responsibles,
    classLessons,
    teachers,
    notifications,
    settings,
    murals,
    lessonResults,

    // Dashboard

    dashboard,

    // Student Home

    activityStudent,

    // Teacher Home

    classTeacher
  },
  strict: process.env.DEV,
})
