/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    users: [],
    usersShow: {},
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getUserById: (state) => (id) => {
      return state.users.find(user => user.id === id)
    }
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users
    },
    ADD_USER(state, item){
      state.users.unshift(item)
    },
    UPDATE_USER(state, user) {
      const userIndex = state.users.findIndex((p) => p.id == user.id)
      Object.assign(state.users[userIndex], user)
    },
    REMOVE_USER(state, id) {
      const userIndex = state.users.findIndex((p) => p.id == id)
      state.users.splice(userIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchUsers({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/users', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_USERS', data)
          } else {
            commit('SET_USERS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/users/${args.users_id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated) {
            commit('SET_USERS', [data.data])
          } else {
            commit('SET_USERS', [data])
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/users/${data.id}`, data)
        .then((response) => {
            context.commit('UPDATE_USER', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    updatePhoto(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("photo", data.photo );
        axios.post('/update-user-photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
            context.commit('UPDATE_USER', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/users/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_USER', response.data.id) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 
    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/users',data).then((response) => {
          commit('ADD_USER', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },



    
  },
}
